import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("isLoggedIn");

  if (!isLoggedIn) {
    navigate('/homepage');
    return null;
  }
  return <Outlet />;
};

export default PrivateRoute;
