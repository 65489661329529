import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import API_URL from '../utils/config';
import ReactMarkdown from "react-markdown";
import { Select, MenuItem } from "@mui/material";
import akirabrand from "../static/akira-ai-brand-logo.svg";
import sendicon from "../static/send-button.svg";
import chathistoryicon from "../static/search-history-icon.svg";
import updatesicon from "../static/updates-and-faq-icon.svg";
import darkmodeicon from "../static/dark-mode-icon.svg";
import accessibilityicon from "../static/accessibility-icon.svg";
import { ThreeDots } from "react-loader-spinner";
import Header from "./Header";
import "./style.css";
import "../common/grid-new.css";
import "../common/primary-new.css";

function HomePage() {
  const [isTyping, setIsTyping] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedLink, setSelectedLink] = useState(0);
  const messageContainerRef = useRef(null);
  const [userQuestion, setUserQuestion] = useState("");
  const [conversation, setConversation] = useState([]);
  const [queryHistory, setQueryHistory] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCopilotSectionHidden, setIsCopilotSectionHidden] = useState(false);
  const [isChatMessageSectionHidden, setIsChatMessagetSectionHidden] = useState(
    true
  );
  const scrollToLatestMessage = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToLatestMessage();
  }, [conversation]);

  const startNewConversation = () => {
    setConversation([]);
    setIsCopilotSectionHidden(true); // Set isCopilotSectionHidden to true
    const initialMessage = `Hi! How may I assist you?`;
    setConversation([{ text: initialMessage, type: "bot" }]);
  };

  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    const newSessionGenerated = sessionStorage.getItem("newSessionGenerated");
    if (!newSessionGenerated) {
      const restoreChatHistory = sessionStorage.getItem("chatHistory");
      const restoreQuery = sessionStorage.getItem("queries");
      if (restoreChatHistory) {
        setConversation(JSON.parse(restoreChatHistory));
      }
      if (restoreQuery) {
        setQueryHistory(JSON.parse(restoreQuery));
      }
    } else {
      sessionStorage.setItem("chatHistory", JSON.stringify(conversation));
      sessionStorage.setItem("queries", JSON.stringify(queryHistory));
      sessionStorage.setItem("newSessionGenerated", "false");
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("chatHistory", JSON.stringify(conversation));
    sessionStorage.setItem("queries", JSON.stringify(queryHistory));
  }, [conversation, queryHistory]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsCopilotSectionHidden(true); // Set isCopilotSectionHidden to true
    setIsChatMessagetSectionHidden(false);

    const newConversation = [...conversation];
    if (userQuestion) {
      newConversation.push({ text: userQuestion, type: "user" });
    }
    setConversation(newConversation);

    try {
      if (userQuestion) {
        setLoading(true);
        const response = await axios.post(
          `https://databricks-middelware.lab.neuralcompany.team/api/sec`,
          {
            dataframe_split: {
              columns: ["query"],
              data: [[userQuestion]],
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer dapi7ca41744cf2c62186e8282584d18e6ba`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
  
        if (response.status === 200) {
          const data = response.data;
          if (data && data.predictions && data.predictions.length > 0) {
            const botAnswer = data.predictions[0];
            const words = botAnswer.split(' ');
            const botMessage = {
              text: botAnswer,
              type: "bot",
            };
            const displayAnswer = async () => {
              setIsTyping(true);
              const words = botAnswer.split(' ');
              let fullAnswer = ''; 
              for (const word of words) {
                fullAnswer += word + ' ';
                const newBotMessage = {
                  text: fullAnswer.trim(),
                  type: 'bot',
                };
                setConversation((prevConversation) => {
                  const updatedConversation = [...prevConversation];
                  updatedConversation[prevConversation.length - 1] = newBotMessage;
                  return updatedConversation;
                });
                scrollToBottom();
                await new Promise((resolve) => setTimeout(resolve, 50));
              }
              setIsTyping(false);
            };
            newConversation.push(botMessage);
            displayAnswer(botAnswer);
          } else {
            console.error("API response is missing the expected data structure.");
          }
        } else {
          console.error("Error fetching data from API:", response.status);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }

    setQueryHistory([...queryHistory, userQuestion]);
    setUserQuestion("");
    setConversation(newConversation);
    scrollToLatestMessage();
  };
  const typewriterEffect = (element) => {
    const text = element.innerText;
    element.innerText = "";

    let charIndex = 0;
    const typingInterval = setInterval(() => {
      if (charIndex < text.length) {
        const currentChar = text.charAt(charIndex);
        if (currentChar === " ") {
          element.innerHTML += "&nbsp;";
        } else {
          element.innerText += currentChar;
        }
        charIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 70);
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  return (
    <div className="sec-chatbot-wrapper">
      <div className="app-wrapper">
        <div
          className={`hamburger-menu ${isSidebarOpen ? "open" : ""}`}
          onClick={toggleSidebar}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3">
          <div
          className={`sidenav ${isSidebarOpen ? "sidenavigation-open" : ""}`}
        >
          <div className="button-wrapper">
            <div className="upper-button-wrapper">
              <Select
                value={selectedDocument}
                onChange={(e) => setSelectedDocument(e.target.value)}
                sx={{
                  width: 100,
                  height: 25,
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  color: "white",
                  border: "1px solid #fff",
                }}
              >
                <div className="dropdown-heading" value={0}>
                  Document
                </div>
                <MenuItem value={1}>10K</MenuItem>
                {/* <MenuItem value={2}>Document 2</MenuItem>
                        <MenuItem value={3}>Document 3</MenuItem>
                        <MenuItem value={4}>Document 4</MenuItem>
                        <MenuItem value={5}>Document 4</MenuItem> */}
              </Select>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                sx={{
                  width: 100,
                  height: 25,
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  color: "white",
                  border: "1px solid #fff",
                }}
              >
                <div className="dropdown-heading" value={0}>
                  Year
                </div>
                <MenuItem value={1}>2023</MenuItem>
                <MenuItem value={2}>2022</MenuItem>
                {/* <MenuItem value={3}>2022</MenuItem>
                        <MenuItem value={4}>2021</MenuItem>
                        <MenuItem value={5}>2020</MenuItem> */}
              </Select>
            </div>
            <div className="upper-button-wrapper">
              <Select
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                sx={{
                  width: 100,
                  height: 25,
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  color: "white",
                  border: "1px solid #fff",
                }}
              >
                <div className="dropdown-heading" value={0}>
                  Company
                </div>
                <MenuItem value={1}>Walmart</MenuItem>
                <MenuItem value={2}>Tesla</MenuItem>
                {/* <MenuItem value={3}>Amazon</MenuItem>
                        <MenuItem value={4}>Databricks</MenuItem>
                        <MenuItem value={5}>Google</MenuItem> */}
              </Select>
              <Select
                value={selectedLink}
                onChange={(e) => setSelectedLink(e.target.value)}
                sx={{
                  width: 100,
                  height: 25,
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  color: "white",
                  border: "1px solid #fff",
                }}
              >
                <div className="dropdown-heading" value={0}>
                  Link
                </div>
                <MenuItem value={1}>Document 1</MenuItem>
                <MenuItem value={2}>Document 2</MenuItem>
                <MenuItem value={3}>Document 3</MenuItem>
                <MenuItem value={4}>Document 4</MenuItem>
                <MenuItem value={5}>Document 4</MenuItem>
              </Select>
            </div>
            <div className="new-complaint-button">
              <button id="new-conversation" onClick={startNewConversation}>
                {"New Conversation"}
              </button>
            </div>
            <div className="search-history-wrapper">
              {queryHistory.map((query, index) => (
                <div className="query-history-items-wrapper" key={index}>
                  <div
                    className="query-history-item"
                    onClick={() => setUserQuestion(query)}
                  >
                    <img
                      src={chathistoryicon}
                      alt="Query-history-icon"
                      id="query-history-icon"
                    />
                    <p style={{ whiteSpace: "nowrap" }}>
                      {query
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")}
                      {query.split(" ").length > 4 ? "..." : ""}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="sidenav-services-wrapper">
            <ul>
              <li>
                <img src={accessibilityicon} alt="" className="sidenav-icon" />
                <a href="#">Tools And Accessibility</a>
              </li>
              <li>
                <img src={darkmodeicon} alt="" className="sidenav-icon" />
                <a href="#">Dark Mode</a>
              </li>
              <li>
                <img src={updatesicon} alt="" className="sidenav-icon" />
                <a href="#">Updates and FAQ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
          <div
          className={`chatbot-content ${
            isSidebarOpen ? "chatbot-content-hidden" : ""
          }`}
        >
          <Header />
          {!isCopilotSectionHidden && (
            <div className="copilot-section">
              <div className="container prompt-container-wrapper">
                <section className="prompts-part">
                  <div className="bot-image">
                    <img
                      src={akirabrand}
                      alt="akira-ai-brand-logo"
                      id="akira-brand-icon"
                    />
                  </div>
                  <div className="assistant-heading">
                    <h2>
                      <span className="bold-text">InsightsGA</span> - Generative
                      Agent for Financial Analyst
                    </h2>
                  </div>
                  <div className="row suggested-prompt-row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Business</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          What products and services are they providing?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Risk analysis</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          What is the impact of Ukraine war/Israil war/pandemic?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Properties</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          What properties Company have?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Legal Proceeding</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          Are there any legal proceedings against the company?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Stocks</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          What are the number of Shareholders?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Financial Analysis</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          What is the current revenue of the company?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Market Risk Analysis</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          Does the company have any commodity risk?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Board Of Directors</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          Who are the Board of directors?
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                      <div className="prompts-card">
                        <h3>Sustainability Analysis</h3>
                        <p onClick={(e) => setUserQuestion(e.target.innerText)}>
                          What are the company's environmental impact?
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}

          {!isChatMessageSectionHidden && (
            <div className="chat-messages" ref={messageContainerRef}>
              {conversation.map((message, index) => (
                <div
                  className={`messages-align ${
                    message.type === "bot" ? "bot" : "user"
                  }`}
                  key={index}
                >
                  <div className="avatar">
                    {message.type === "user" ? (
                      <p></p>
                    ) : (
                      <div className="response">
                        <img
                          src={akirabrand}
                          alt="Bot Avatar"
                          className="bot-avatar"
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`message ${
                      message.type === "user" ? "user" : "bot"
                    }`}
                  >
                    <div className="message-content">
                      {message.type === "bot" &&
                        message.text.includes("Suggested Questions:") && (
                          <>
                            <p>
                              {message.text
                                .split("Suggested Questions:")[0]
                                .trim()}
                              <br />
                              <br />
                              Suggested Questions:
                            </p>
                            <div className="suggested-questions">
                              {message.text
                                .split("Suggested Questions:")[1]
                                .split("\n")
                                .map((question, idx) => (
                                  <p
                                    key={idx}
                                    onClick={() =>
                                      setUserQuestion(
                                        question.replace(/^\d+\./, "").trim()
                                      )
                                    }
                                  >
                                    {question.replace(/^\d+\./, "").trim()}
                                  </p>
                                ))}
                            </div>
                          </>
                        )}
                      {message.type === "bot" &&
                        !message.text.includes("Suggested Questions:") && (
                          <p>{message.text}</p>
                        )}
                      {message.type === "user" && (
                        <div className="message user">
                          <p>{message.text}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <form onSubmit={handleSubmit} className="chat-input">
            <input
              type="text"
              placeholder="Enter your Queries"
              value={userQuestion}
              onChange={(e) => setUserQuestion(e.target.value)}
            />
            {loading ? (
              <ThreeDots
                height="80"
                width="60"
                radius="9"
                color="#2d6ada"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <img
                src={sendicon}
                onClick={handleSubmit}
                alt="send-icon"
                id="send-icon"
              />
            )}
          </form>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
