import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import Login from './components/Login';
import PrivateRoute from './PrivateRoute';

const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
const App = () => {
  return (
<BrowserRouter>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/homepage" element={<HomePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);