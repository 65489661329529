import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import akiraailogo from "../static/akira-ai-brand-logo.svg";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isAuthenticated = localStorage.getItem("isLoggedIn") === "true";

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/homepage");
    }
  }, [navigate, isAuthenticated]);

  const handleLogin = () => {
    if (!username || !password) {
      setErrorMessage("Please complete the required fields.");
      return;
    }
    if (username === "hackathon@akira.ai" && password === "test123") {
      localStorage.setItem("isLoggedIn", "true");
      navigate("/homepage");
    } else {
      setErrorMessage("Wrong credentials. Please try again.");
    }
  };

  return (
    <div className="login-page">
      <div className="left-section">
        <div className="login-section">
          <div className="login-logo">
            <img src={akiraailogo} alt="akira-ai-logo" />
          </div>
          <h2>Login to your Account</h2>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              Remember Me
            </label>
          </div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <button className="login-button" onClick={handleLogin}>Login</button>
        </div>
      </div>
      <div className="blue-section">
        <div className="blue-section-heading-wrapper">
          <h2>Boost your productivity by 33%</h2>
          <p>
            Get Real Time Insights with Zero Dependency using Foundation Agents
            on Hybrid Cloud
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 cards-outer-wrapper">
            <div className="cards-wrapper">
              <h3>DATA GA</h3>
              <p>
                Generative Agent for Analyzing the structured data and build BI
                reports backed by data LLM
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 cards-outer-wrapper">
            <div className="cards-wrapper">
              <h3>VISION GA</h3>
              <p>Generative Agent for assisting in vision tasks by Vision LLM</p>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 cards-outer-wrapper">
            <div className="cards-wrapper">
              <h3>SEARCH GA</h3>
              <p>
                Enterprise search made easy using search LLM, empowering the
                search agents
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 cards-outer-wrapper">
            <div className="cards-wrapper">
              <h3>GRAPH GA</h3>
              <p>
                Use the power of Knowledge graphs with graph Generative Agent
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 cards-outer-wrapper">
            <div className="cards-wrapper">
              <h3>INSIGHT GA</h3>
              <p>
                Enhancing Contact Centre Intelligence with automation
                capabilities of search Generative agent
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 cards-outer-wrapper">
            <div className="cards-wrapper">
              <h3>SYNTH GA</h3>
              <p>
                Utilize synthetic data for creating simulating environments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
